import React from 'react'
import {Link} from 'react-router-dom'

import notFoundStyle from './assets/NotFound.module.scss'

import Content from '../../layout/content/Content'

const NotFound = () => (
  <Content>
    <div className={notFoundStyle.notfound}>
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64" height="64" viewBox="0 0 172 172" fill="#ffffff">
        <path d="M71.66667,14.33333c-31.57944,0 -57.33333,25.75389 -57.33333,57.33333c0,31.57944 25.75389,57.33333 57.33333,57.33333c14.50482,0 27.74719,-5.46789 37.86295,-14.40332l5.13705,5.13705v9.26628l28.66667,28.66667l14.33333,-14.33333l-28.66667,-28.66667h-9.26628l-5.13705,-5.13705c8.93544,-10.11576 14.40332,-23.35813 14.40332,-37.86295c0,-31.57944 -25.7539,-57.33333 -57.33333,-57.33333zM71.66667,28.66667c23.83313,0 43,19.16687 43,43c0,23.83313 -19.16687,43 -43,43c-23.83313,0 -43,-19.16687 -43,-43c0,-23.83313 19.16687,-43 43,-43zM50.16667,57.33333c-3.95804,0 -7.16667,3.20863 -7.16667,7.16667c0,3.95804 3.20863,7.16667 7.16667,7.16667c3.95804,0 7.16667,-3.20863 7.16667,-7.16667c0,-3.95804 -3.20863,-7.16667 -7.16667,-7.16667zM93.16667,57.33333c-3.95804,0 -7.16667,3.20863 -7.16667,7.16667c0,3.95804 3.20863,7.16667 7.16667,7.16667c3.95804,0 7.16667,-3.20863 7.16667,-7.16667c0,-3.95804 -3.20863,-7.16667 -7.16667,-7.16667zM71.66667,78.80534c-8.76187,0 -17.52898,2.64054 -24.94336,7.93652l8.32845,11.67383c9.75291,-6.96637 23.46291,-6.96637 33.21582,0l8.32846,-11.67383c-7.41438,-5.29599 -16.16749,-7.93652 -24.92936,-7.93652z" />
      </svg>
      <h1>Stránka nenalezena</h1>
      <Link to="/">Přejít na hlavní stránku</Link>
    </div>
  </Content>
)

export default NotFound
